.Blog-Top-Line {
    border-bottom: 2px solid var(--dark-pink-color);
    width: 100%;
    height: 2px;
    margin-bottom: 3px;
}

.Blog-Bottom-Line {
    border-bottom: 1px solid var(--medium-dark-pink-color);
    width: 100%;
    height: 1px;
}

.max-inline-size-600-screen-control{
    max-inline-size: 500px;
}

@media only screen and (max-width: 900px) {
    .max-inline-size-600-screen-control {
        max-inline-size: 400px;
    }
}

@media only screen and (max-width: 500px) {
    .max-inline-size-600-screen-control {
        max-inline-size: 300px;
    }
}

@media only screen and (max-width: 400px) {
    .max-inline-size-600-screen-control {
        max-inline-size: 220px;
    }
}