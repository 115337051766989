.Video-Style {
    width: 60%;
    aspect-ratio: 2/1;
    /* height: auto; */
    min-width: 15rem;
}

.Video-Style iframe {
    /* left: 0;
  top: 0; */
    height: 100%;
    width: 100%;
    /* position: absolute; */
}