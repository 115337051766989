.GBV_Parent_Container {
    overflow-x: hidden;
}

.GBV-Container {
    /* margin: 0rem 0.5rem 2rem 0rem;
    display: flex; */
    row-gap: 5rem;
    column-gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    flex-direction: column;
}

.Questions-Container {
 
    row-gap: 3rem;
    column-gap: 3rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 2rem;
    margin-top: 2rem;
    flex-direction: column;
}

.Questions-Container-Admin {

    row-gap: 3rem;
    column-gap: 3rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 2rem;
    margin-top: 2rem;
    flex-direction: column;
    min-width: 250px;
    max-width: 75%;
}

.GBV-Option-Button {
    /* height: 30px; */
    width: fit-content;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    border-radius: 6px;
}

.GBV-Submit-Button {
    /* height: 30px; */
    width: fit-content;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 20px;
    background-color: var(--light-orange-color);
    color: white;
}

.GBV-Submit-Button:hover {
    background-color: var(--dark-orange-color);
}

.GBV-Background-Err {
    background-color: lightcoral;
    border-radius: 10px;
    padding: 5px;
}

.GBV-Top-Button {
    background-color: #E6BA38;
    font-size: 30px;
    color: white;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.GBV-Bottom-Button {
    background-color: #E6BA38;
    font-size: 19px;
    color: white;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    width: 105px;
    text-align: center;
}

.GBV-BOX {
    padding: 10px;
    border: 4px solid var(--dark-pink-color);
    background-color: rgb(245, 242, 242);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
}

.GBV-BOX-Button {
    font-size: 20px;
    color: var(--dark-pink-color);
    font-weight: bold;
}