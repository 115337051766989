.Header {
  /* width:fit-content; */
  height: fit-content;
  /* min-height: 75px; */

  margin: 0;
  top: 0;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* z-index: 1; */

  display: flex;

  /* justify-content: space-around; */
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  /* column-gap: 10px;
  row-gap: 10px; */
  top: 0;
  left: 0;
  right: 0;
/* 
  //#F3912E; */
  /* background: #f6c9a49c; */
  /* background: #ed59278e; */
  /* background-color: #f3902e90;
  border: 1px solid var(--light-blue-color); */

  overflow: hidden;
  background-color: #E4E8E2;
  color: #AC91C4;
  /* position:absolute; */
  /* padding: 5px; */
  z-index: 99;

}

.Header-Down {
  height: fit-content;
  min-height: 75px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #E4E8E2;
  border: 1px solid var(--light-pink-color);
  overflow: hidden;
  padding: 5px;
  z-index: 99;
  width: 100%;
}


.Header-Icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 15px;
  /* margin-top: 0px; */
}
.Header-Icon, .Header-Icon > svg{
  /* margin-left: 10px;
  margin-right: 10px; */
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: var(--medium-orange-color);
}
.Header-Icon:hover > svg {
  color: var(--light-orange-color);
  transition: 0.5s;
}

.Header-Navigation-Icon,
.Header-Navigation-Icon>svg {
  height: 35px;
  width: 35px;
  color: var(--light-orange-color);
  border-radius: 5px;
}

.Top-Header-Navigation-Icon,
.Top-Header-Navigation-Icon>svg {
  height: 34px;
  width: 34px;
  color: var(--light-orange-color);
  border-radius: 5px;
  margin-top: 0.2rem;
}
.Small-Top-Header-Navigation-Icon,
.Small-Top-Header-Navigation-Icon>svg {
  height: 25px;
  width: 25px;
  color: var(--light-orange-color);
  border-radius: 5px;
}

.Selected > span {
  color: white !important;
}
.Selected {
  background-color: #AC91C4;
}

/* .Header-Navigation-Icon:hover>svg {
  color: black;
  transition: 0.5s;
} */


.Header-Logo {
  /* height: 70px;
  width: 40px; */
 
}

.Header-Logo-Image {
  height: fit-content;
  width: 224px;
  margin-right: 10px;
}

.Header-Navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.Top-Header-Navigation-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  /* font-weight: bold; */
  width: fit-content;
  border-radius: 5px;
  padding: 7px;
}

.Header-Navigation-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  /* font-weight: bold; */
  width:fit-content;
  border-radius: 5px;
  padding: 5px;
}

.Header-Navigation-Item:hover, .Top-Header-Navigation-Item:hover {
  background-color: var(--light-pink-color);
}
.Header-Navigation-Item .Selected, .Top-Header-Navigation-Item .Selected {
  background-color: var(--medium-light-pink-color);
  font-weight: bold;
}

.Header-Top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 2rem;
  column-gap: 40%;
  width: 100%;
  height: fit-content;
  padding: 10px 10px 10px 10px;
  flex-wrap: wrap;
}

.Language-Icons-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.Language-Selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 2px;
  column-gap: 2px;
}

.Selected-Language {
  background-color: var(--light-pink-color);
  border-radius: 5px;
  /* padding: 3px; */
}

.Language-Option {
  padding: 3px;
}

.Separator > svg{
  width: 1.5rem;
  height: 1.5rem;
}

.Resources-Item-Style {
  padding: 0.3rem 0.5rem;
  margin: 0.15rem 0;
  font-size: 14px;
}

.Header-Item-Style {
  padding: 0.3rem 0.5rem;
  margin: 1rem 0;
  font-size: 20px;
  width: 10rem;
}

.Header-Dropdown-Style {
  /* width: 20rem; */
}

.Header-Container-Style {
  /* width: 20rem; */
}
.Header-Label-Style {
  /* width: 20rem; */
  background-color: #E4E8E2;
}
