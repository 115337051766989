.About-Container {
    /* height: fit-content; */
    /* min-height: 75px; */
    /* margin: 0; */
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
    flex-wrap: wrap;
    /* text-align: center; */
    /* column-gap: 10px;
    row-gap: 10px; */

    background-color: #ffffff;
    /* border: 1px solid var(--light-pink-color); */
    /* overflow: hidden; */
    overflow-x: hidden;
    /* padding: 2rem 12% 2rem 12%; */
    /* margin: 0 5rem 0 5rem; */
    /* margin-top: 2rem; */
    /* width: 80%; */
    /* row-gap: 2rem;
    column-gap: 2rem; */
    /* align-items: center; */
}

.About-Image-Logo {
    min-width: 10rem;
    /* min-height: 15rem; */
    max-width: 18rem;
    /* aspect-ratio: 3/2; */
    margin-right: 6rem;
}

.About-Image {
    min-width: 10rem;
    /* min-height: 15rem; */
    max-width: 23rem;
    aspect-ratio: 3/2;
}
.About-Image1 {
    min-width: 10rem;
    /* min-height: 15rem; */
    max-width: 23rem;
    aspect-ratio: 2/2;
}