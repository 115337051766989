.Footer-Container {
    width:100;
    min-height: 15rem;
    background-image: linear-gradient(#C9B9DA, #ECBC6C);
    /* background-color: var(--light-orange-color); */
    padding: 2rem;
}

.Footer-Image {
    width: 15rem;
    height: auto;
}