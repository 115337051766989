.Selection-Container {
    position: absolute;
    margin: 0;
    /* height: 10rem; */
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.Selection-Label-Button {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    /* min-width: 3rem; */
    /* font-size: 0.9rem;
    font-weight: 500; */
    background-color: #E4E8E2;
    border: none;
    border-radius: 5px;
    color: #111;
    align-items: center;
    /* justify-content: space-between; */
    /* border: 1px solid slategrey; */
    cursor: pointer;
    /* box-shadow: 0 1px 4px 0 #ccc; */
    transition: 0.3s ease;
    width: 6rem;
}

.Dropdown-Style {
    position: relative;
    /* top: 5;
    left: 0; */
    max-height: 50vmax;
    /* min-width: 7rem; */
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #fafafa;
    border: 1.5px solid slategrey;
    transition: max-height 0.2s ease;
    overflow-y: auto;
    overflow-x: hidden;
    justify-items: center;
    align-items: center;
    display: none;
}

.Dropdown-Item {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
    /* width: 90%; */
    /* margin: 0.15rem 0;
    padding: 0.3rem 0.5rem; */
    /* font-size: 14px; */
    /* font-weight: 400; */
    color: #333;
    border-radius: 0.3rem;
    cursor: pointer;
    /* width: 6rem; */
}
.Dropdown-Item:hover {
    background-color: var(--light-pink-color);
}

