.Survey {
    background-color: var(--light-pink-color);
    width: fit-content;
    padding: 1rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.Option {
    background-color: var(--light-orange-color);
    padding: 0.5rem;
    margin: 0.5rem;
    width: 8rem;
    text-align: center;
    cursor: pointer;
}
.Option:hover {
    transform: scale(1.1);
    transition: 0.2s;
}