.Podcast-Player {
    width: 100%;
        height: 200px;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 6px;
        overflow: hidden;
}

.Podcast-Iframe {
    width: 100%;
    height: 200px;
}