.Resources_Parent_Container {
    overflow-x: hidden;
}

.Resources-Container {
    /* margin: 0rem 0.5rem 2rem 0rem;
    display: flex; */
    row-gap: 5rem;
    column-gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
}

.Left-Navigation-Bar {
    display: flex;
    min-width: 11rem;
    /* max-width: 30rem; */
    height: fit-content;
    /* max-width: 11rem; */
    /* background-color: var(--light-orange-color); */
    /* flex-grow: 1;
    flex-shrink: 8; */
    /* border: 1px solid var(--light-pink-color);
    border-radius: 5px; */
}

.Selected-Resources-Container {
    /* flex-grow: 8;
    flex-shrink: 1; */
    /* background-color: brown; */
    /* min-width: 768px; */
    /* padding: 1rem; */
    /* margin-top: 2rem; */
    min-width: 15rem;
    width: 15rem;
    flex-grow: 1;

}

@media only screen and (max-width : 768px){
    .Resources-Container {
            flex-wrap: wrap;
        }
    .Left-Navigation-Bar {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .Selected-Resources-Container {
        /* margin-left: 1rem;
        margin-right: 1rem; */
       
        flex-direction: column;
    }
}
@media only screen and (min-width : 768px) {
    .Resources-Container{
        flex-direction: row;
    }
    .Left-Navigation-Bar {
        flex-direction: column;
    }
    .Selected-Resources-Container {
            /* margin-left: 2rem;
            margin-right: 2rem; */
            flex-direction: row;
    }
}

.Selected-Resources-Item {
    border: 0.5px solid var(--dark-pink-color);
    border-radius: 5px;
    width:11rem;
}

.Resources-Selection-Icon > svg {
    width : 1.5rem;
    height: 1.5rem;
    color: var(--orange-color)
}

.Rescources-Selected {
    background-color: var(--medium-dark-pink-color);
}
.Rescources-Selected .Resources-Selection-Icon > svg {
    color :black;
}