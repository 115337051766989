.Home-Container {
    /* height: fit-content; */
    /* min-height: 75px; */
    /* margin: 0; */
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
    flex-wrap: wrap;
    /* text-align: center; */
    /* column-gap: 10px;
    row-gap: 10px; */

    background-color: #ffffff;
    /* border: 1px solid var(--light-pink-color); */
    /* overflow: hidden; */
    overflow-x: hidden;
    /* padding: 2rem 12% 2rem 12%; */
    /* margin: 0 5rem 0 5rem; */
    /* width: 80%; */
    /* row-gap: 2rem;
    column-gap: 2rem; */
    justify-content: center;
    align-items: center;
}


.Home-Block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    /* justify-content: start; */
}

.Home-Block-2 {
    min-width: 200px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Home-Block-3-Wrap {
    background: url('../../assets/images/Re-design assets/Copy of Home segment background_.png') no-repeat center;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.Home-Block-3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 200px;
    max-width: 600px;
}


.Home-Ticket-Image {
    min-width: 10rem !important;
    /* min-height: 15rem; */
    width: 15rem;
    aspect-ratio: auto;
}

.Bulletin {
    border-radius: 5px;
    border-color: blue;
    border-width: 5px;
    padding: 15px;
    background-color: #E4E8E2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Tickets-Container{
    display: flex;
    column-gap: 100px;
    row-gap: 100px;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #E4E8E2;
    padding: 2rem;
    width: 100%;
}