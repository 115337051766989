@import url('https://fonts.googleapis.com/css?family=Caveat');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --light-orange-color: #e0ba85;
  --orange-color: #ecbc6c;
  --dark-orange-color: #fbcc2c;

  --light-pink-color: #E6DFE9;
  --medium-light-pink-color: #d4c4e4;
  --medium-dark-pink-color: #c9b9da;
  --dark-pink-color: #ac94c4;
}

.color-pink {
  color: #AC91C4;
}

.color-white {
  color: white;
}

.background-color-light-grey {
  background-color: #E4E8E2;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media only screen and (max-width: 768px) {
  .parent-container-x-margins {
    margin-left: 4vw;
  }

  .mobile-image {
    width: 15rem !important;
  }
}

@media only screen and (min-width: 767px) {
  .parent-container-x-margins {
    margin-left: 12vw;
    margin-right: 12vw;
  }

  .parent-container-x-margins-narrow {
    margin-left: 15vw;
    margin-right: 15vw;
  }
}

.background-flourish {
  background: url('../images/Re-design assets/Copy of Main background design.png') no-repeat center fixed;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ;
}

.position-relative {
  position: relative;
}

.img-wrap {
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding-bottom: 3rem;
}

.flourish-background-img-landing {
  display: block;
  position: absolute;
  /* top: -130px; */
  top: 1%;
  left: 50%;
  /* min-height: 100%; */
  width: 90%;
  transform: translate(-50%, 0);
  z-index: 0; 
}

.flourish-background-img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.background-color-for-image {
    background-color: #E4E8E2;
      position: absolute;
    height: 100vh;
    width: 100%;
      z-index: -1;
}

.landing-page-text {
  position: absolute;
  left: 10%;
  width: 300px;
  top: calc(90px + 24vw );
  inline-size: 650px;
  /* overflow-wrap: break-word; */
  z-index: 2;
}

@media only screen and (min-width: 1200px) {
  .landing-page-text {
    inline-size: 650px;
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page-text {
    inline-size: 450px;
  }
}

@media only screen and (max-width: 800px) {
  .landing-page-text {
    inline-size: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .landing-page-text {
    inline-size: 200px;
  }
}

.background-flourish-noflex {
  background: url('../images/Re-design assets/Copy of Main background design.png') no-repeat center fixed;
  background-size: cover;
}

.backround-img-yellow {
  background: url('../images/Re-design assets/Copy of about us segment background.png') no-repeat center;
  background-size: cover;
}

.sticky {
  position: sticky !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.border-light-blue {
  border-color: var(--light-blue-color);
}

.border-light-orange {
  border-color: var(--light-orange-color);
}

.border-width-3-px {
  border-width: 3px;
}

.border-right-solid {
  border-right-style: solid;
}

.border-rigth-3-px {
  border-right-width: 3px;
}

.padding-x-0-5-rem {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.padding-x-1-rem {
  padding-left: 1rem;
  padding-right: 1rem;
}


.padding-y-0-5-rem {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padding-2-px {
  padding: 2px;
}

.padding-0-5-rem {
  padding: 0.5rem;
}

.padding-1-rem {
  padding: 1rem;
}

.padding-2-rem {
  padding: 2rem;
}

.padding-25-pcnt {
  padding: 25%;
  /* padding-top: 25%; */
}

.padding-top-3-rem {
  padding-top: 3rem;
}

.margin-x-1-rem {
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin-x-2-px {
  margin-left: 2px;
  margin-right: 2px;
}

.margin-0-rem {
  margin: 0rem;
}

.margin-3-px {
  margin: 0.5px;
}

.margin-5-px {
  margin: 1px;
}

.margin-1-rem {
  margin: 1rem;
}

.margin-2-rem {
  margin: 2rem;
}

.margin-top-0-5-rem {
  margin-top: 0.5rem;
}

.margin-top-1-rem {
  margin-top: 1rem;
}

.margin-top-2-rem {
  margin-top: 2rem;
}

.margin-top-5-rem {
  margin-top: 5rem;
}

.margin-top-3-rem {
  margin-top: 3rem;
}

.margin-top-10-rem {
  margin-top: 10rem;
}

.margin-bottom-0-5-rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1-rem {
  margin-bottom: 1rem;
}

.margin-bottom-2-rem {
  margin-bottom: 2rem;
}

.margin-left-0-5-rem {
  margin-left: 0.5rem;
}

.margin-left-3-rem {
  margin-left: 3rem;
}

.margin-right-0-5-rem {
  margin-right: 0.5rem;
}

.margin-right-1-rem {
  margin-right: 1rem;
}

.margin-right-3-rem {
  margin-right: 3rem;
}

.margin-right-4-rem {
  margin-right: 4rem;
}

.margin-right-5-rem {
  margin-right: 5rem;
}

.color-orange {
  color: var(--orange-color);
}

.color-blue {
  color: var(--blue-color);
}

.color-grey {
  color: gray;
}

.color-dark-pink {
  color: var(--dark-pink-color)
}

.color-light-pink {
  color: var(--light-pink-color);
}

.color-blue-hover {
  color: var(--blue-color);
}

.color-blue-hover:hover {
  color: var(--light-blue-color);
  transition: 0.5s;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-bottom-5-px {
  margin-bottom: 5px;
}

.font-size-10-px {
  font-size: 10px;
}

.font-size-12-px {
  font-size: 12px;
}

.font-size-13-px {
  font-size: 13px;
}

.font-size-14-px {
  font-size: 14px;
}

.font-size-16-px {
  font-size: 16px;
}

.font-size-18-px {
  font-size: 18px;
}

.font-size-20-px {
  font-size: 20px;
}

.font-size-22-px {
  font-size: 22px;
}

.font-size-24-px {
  font-size: 24px;
}

.font-size-28-px {
  font-size: 28px;
}

.font-size-30-px {
  font-size: 30px;
}

.font-size-35-px {
  font-size: 35px;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.font-blue {
  color: #3366CC;
}

.collection-icon,
.collection-icon>svg {
  width: 30px;
  height: 30px;
}

.collection-icon:hover>svg {
  color: var(--light-orange-color);
  transition: 0.5s;
  z-index: 2;
}

.cursor-pointer {
  cursor: pointer;
}

.center-item {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-x-relative {
  transform: translateX(calc(50vw - 50%));
}

.center-y-relative {
  transform: translateY(calc(50vh - 50%));
}

.center-relative {
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}

.margin-left-5-px {
  margin-left: 5px;
}

.margin-top-1-rem {
  margin-top: 1rem;
}

.max-width-70-rem {
  max-width: 70rem;
}

.max-width-30-rem {
  max-width: 30rem;
}

.max-width-40-rem {
  max-width: 40rem;
}

.min-height-15-rem {
  min-height: 15rem;
}

.min-width-15-rem {
  min-width: 15rem;
}

.min-width-20-rem {
  min-width: 20rem;
}


.width-fit {
  width: fit-content;
}

.width-2-rem {
  width: 2rem;
}

.width-3-rem {
  width: 3rem;
}

.width-4-rem {
  width: 4rem;
}

.width-5-rem {
  width: 5rem;
}

.width-6-rem {
  width: 6rem;
}

.width-7-rem {
  width: 7rem;
}

.width-8-rem {
  width: 8rem;
}

.width-10-rem {
  width: 10rem;
}

.width-12-rem {
  width: 12rem;
}

.width-15-rem {
  width: 15rem;
}

.width-20-rem {
  width: 20rem;
}

.width-25-rem {
  width: 25rem;
}

.width-30-rem {
  width: 30rem;
}

.width-35-rem {
  width: 35rem;
}

.width-20-percent {
  width: 20%;
}

.width-30-percent {
  width: 30%;
}

.width-40-percent {
  width: 40%;
}

.width-50-percent {
  width: 50%;
}

.width-60-percent {
  width: 60%;
}

.width-80-percent {
  width: 80%;
}

.width-85-percent {
  width: 85%;
}

.width-90-percent {
  width: 90%;
}

.width-100-percent {
  width: 100%;
}

.width-30-vw {
  width: 30vw;
}

.max-width-600-px {
  max-width: 600px;
}

.max-width-575-px {
  max-width: 575px;
}

.height-1-rem {
  height: 1rem;
}

.height-1-7-rem {
  height: 1.7rem;
}

.height-2-rem {
  height: 2rem;
}

.height-3-rem {
  height: 3rem;
}

.height-4-rem {
  height: 4rem;
}

.height-10-rem {
  height: 10rem;
}

.height-15-rem {
  height: 15rem;
}

.height-20-rem {
  height: 20rem;
}

.height-fit {
  height: fit-content;
}

.height-100-vh {
  height: 100vh;
}

.height-100-percent {
  height: 30rem;
}

.right-8-rem {
  right: 8rem;
}

.left-negative-1-rem {
  left: -1rem
}

.left-0-5-rem {
  left: 0.5rem;
}

.left-2-rem {
  left: 2rem;
}

.left-90-percent {
  left: 90%;
}

.top-negative-1-rem {
  top: -1rem
}


.top-1-rem {
  top: 1rem;
}

.top-2-rem {
  top: 2rem;
}

.top-8-rem {
  top: 8rem;
}

.top-4-rem {
  top: 4rem;
}

.top-5-rem {
  top: 5rem;
}

.top-8-rem {
  top: 8rem;
}

.top-9-rem {
  top: 9rem;
}

.top-11-rem {
  top: 11rem;
}

.right-9-rem {
  right: 9rem;
}

.bottom-middle {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.right-middle {
  right: 4rem;
  top: 50%;
  transform: translateY(-50%);
}

.left-middle {
  left: 4rem;
  top: 50%;
  transform: translateY(-50%);
}

.center-top {
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

.z-index-2 {
  z-index: 2;
}

.z-index-neg-1 {
  z-index: -1;
}

.bottom-horizontal-line-blue {
  width: 300px;
  border-bottom-color: var(--blue-color);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.padding-bottom-5-px {
  padding-bottom: 5px;
}

.padding-bottom-2-rem {
  padding-bottom: 2rem;
}

.padding-top-2-rem {
  padding-top: 2rem;
}

.padding-x-2-rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.padding-y-0-5-rem {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.text-center {
  text-align: center;
}

.text-decoration-none>a {
  text-decoration: none !important;
}

.text-decoration-none {
  text-decoration: none !important;
  text-decoration-style: none !important;
}

.color-black {
  color: #000;
}

.selection-tool {
  width: 100px;
  height: 40px;
  border: unset;
  border-radius: 5px;
  color: var(--blue-color);
  background-color: var(--dark-orange-color);
}

.selection-tool:focus {
  width: 100px;
  height: 40px;
  border: unset;
  border-radius: 5px;
  color: var(--blue-color);
  background-color: var(--dark-orange-color);
}

.border-radius-5-px {
  border-radius: 5px;
}

.border-left-radius-5-px {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.border-right-radius-5-px {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.display-flex {
  display: flex;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-shrink-12 {
  flex-shrink: 12;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-12 {
  flex-grow: 12;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.column-gap-0-5-rem {
  column-gap: 0.5rem;
}

.column-gap-1-rem {
  column-gap: 1rem;
}

.column-gap-2-rem {
  column-gap: 2rem;
}

.column-gap-3-rem {
  column-gap: 3rem;
}

.column-gap-4-rem {
  column-gap: 4rem;
}

.column-gap-10-rem {
  column-gap: 10rem;
}

.column-gap-20-rem {
  column-gap: 20rem;
}

.row-gap-0-5-rem {
  row-gap: 0.5rem;
}

.row-gap-1-rem {
  row-gap: 1rem;
}

.row-gap-2-rem {
  row-gap: 2rem;
}

.row-gap-3-rem {
  row-gap: 3rem;
}

.row-gap-4-rem {
  row-gap: 4rem;
}

.row-gap-10-rem {
  row-gap: 10rem;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.text-align-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.aspect-ration-3-1 {
  aspect-ratio: 3/2;
}

.border-box {
  box-sizing: border-box;
}

.no-border {
  border: unset;
}

.background-transparent {
  background: transparent;
}


.vertical-line {
  width: 2rem;
  height: 30vw;
  background-color: transparent;
  border-style: dashed;
  border-color: var(--light-blue-color);
  border-width: 2rem;
  margin: 3rem;
}

.horizontal-line {
  width: 90%;
  height: 16rem;
  background-color: transparent;
  border-style: solid;
  border-color: var(--light-blue-color);
  border-width: 0.1rem;
  margin: 1rem 3rem 3rem 2rem;
}


.background-color-light-blue {
  background-color: var(--light-blue-color);
}

.background-color-light-pink {
  background-color: var(--light-pink-color);
}

.background-color-white {
  background-color: white;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.invisible {
  visibility: hidden;
}

.display-none {
  display: none;
}

.display-block {
  display: block !important;
}

.hover-background-color-light-pink:hover {
  background-color: var(--light-pink-color);
}

.display-inline-flex {
  display: inline-flex;
}

.hover-border-dotted:hover {
  border: 2px dotted var(--dark-pink-color);
}

.background-color-dark-pink {
  background-color: var(--dark-pink-color);
}

.background-color-dark-orange {
  background-color: var(--dark-orange-color)
}

.background-color-light-orange {
  background-color: var(--orange-color)
}

.video-responsive {
  width: 100%;
  aspect-ratio: 2/1;
  /* height: auto; */
  min-width: 15rem;
}

.video-responsive iframe {
  /* left: 0;
  top: 0; */
  height: 100%;
  width: 100%;
  /* position: absolute; */
}

.underline {
  text-decoration: underline;
}

.alarm-border {
  border: 2px solid red !important;
}

.sent-style {
  color: var(--dark-pink-color) !important;
  background-color: lightgreen;
}

.error-style {
  color: black !important;
  background-color: lightcoral;
}

.icons-close-color,
.icons-close-color>svg {
  width: 1rem;
  height: 1rem;
  color: var(--dark-pink-color);
}

.icons-close-color>svg:hover {
  color: black;
}

.border-radius-5-px {
  border-radius: 5px;
}


.Admin-Pin-Admin-page {
  /* width: 22rem; */
  border-radius: 5px;
  /* background-color: #E6DFE9; */
  padding: 3px;
  font-size: 11px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  background-color: thistle;
  margin: 2rem;

}

.font-family-caveat {
  font-family: 'Caveat';
}

.image-fgm {
  min-width: 15rem;
  /* min-height: 15rem; */
  width: 50vw;
  /* width: 3rem; */
  /* aspect-ratio: 2/2; */
}


.li-float-left>li {
  float: left;
}

.pin-hover-effect>svg:hover {
  height: 40px;
  width: 40px;
  color: rgb(112, 61, 84);
  transition: 0.2s;
}

.background-green {
  background-color: green;
}

.background-red {
  background-color: red;
}

#hashLink {
  display: none;
}

#hashLink:target {
  display: block;
}

.textarea {
  width: 80%;
  height: 20vh;
  padding: 20px;
  font-size: 1rem;
  outline: none;
}

.markdown {
  width: 50%;
  height: 100vh;
  padding: 20px;
  outline: none;
}

.Home-Block-Image-0 {
  /* border-radius: 40px; */
  min-width: 15rem;
  /* min-height: 15rem; */
  width: 100%;
  /* aspect-ratio: 3/2; */
}

.Home-Block-Image-0-1 {
  /* border-radius: 40px; */
  min-width: 15rem;
  /* min-height: 15rem; */
  /* width: fit-content; */
  /* width: 50rem !important; */
  /* aspect-ratio: 3/2; */
}

.Home-Block-Image-1 {
  /* border-radius: 40px; */
  min-width: 15rem;
  /* min-height: 15rem; */
  width: 23rem;
  aspect-ratio: 3/2;
}

.Home-Block-Image-2 {
  /* border-radius: 40px; */
  min-width: 18rem;
  /* min-height: 15rem; */
  width: 21rem;
  aspect-ratio: 3/2;
  border-radius: 20px;
}

.min-width-200-px-max-width-240-px {
  min-width: 200px;
  max-width: 240px;
}

.min-width-200-px-max-width-400-px {
  min-width: 200px;
  max-width: 400px;
}

.min-width-200-px-max-width-600-px {
  min-width: 200px;
  max-width: 600px;
}

.Home-Block-Image-3 {
  /* border-radius: 40px; */
  min-width: 15rem !important;
  /* min-height: 15rem; */
  width: 20rem;
  aspect-ratio: auto;
}


@keyframes motion {
  0% {
    /* filter: grayscale(80%); */
    /* filter: brightness(200%); */
  }

  50% {
    /* filter: grayscale(50%) */
  }

  100% {
    /* filter: grayscale(0%); */
    transform: scale(1.06);
    /* filter: brightness(100%); */
  }
}

.image-motion-2 {
  /* position: absolute; */
  animation: motion 10s forwards;
}

.image-m-2 {
  display: inline-block;
  /* padding: 20px; */
  vertical-align: middle;
  color: white;
  /* position: relative; */
  overflow: hidden;
  /* margin: 0 0 10px 0; */
}

.word-wrap-break-word {
  word-wrap: break-word;
}